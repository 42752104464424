import { createColumnHelper } from "@tanstack/react-table"
import { MySubscriptionsTableDataI } from "../tableTypes"
import { ActionMenu } from "../../../../../shared/tanStack/cell/actionsMenu"
import { getMySubscriptionsMenuByRole } from "../../../../../../util/rolePermissions/getByRole"
import { useViewerContext } from "../../../../../../context/ViewerContext"
import { SubscriptionShowSilenceCell } from "../../../sharedComponents/table/cell"

// constants

// Note: column ids are used as keys to style table columns.
// If the column headers are changed in this file, they should be updated in: table.module.scss , header.module.scss
// Note: The column ids are used to keep the urlState in sync with the application state and the column id value will be reflected in the url
// example: /?wellName="PumpMoreOil"
// NOTE: The current implementation of the table will not keep urlState in sync with application state for multisort - to enable mutliSort updates will need to be made, start with the clickHandler that toggles sort ~ /header/index.tsx - <HeaderSortIcon/>

export const RULE_NAME_COLUMN_ID = "ruleName"
export const METRIC_COLUMN_ID = "metric"
export const DESCRIPTION_COLUMN_ID = "description"
export const LAST_ALERT_COLUMN_ID = "lastAlert"
export const SUBSCRIBED_WELLS_COLUMN_ID = "well"

// table columns
const columnHelper = createColumnHelper<MySubscriptionsTableDataI>()

export const mySubscriptionsColumns = [
    columnHelper.accessor("ruleName", {
        id: RULE_NAME_COLUMN_ID,
        header: "Rule Name",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: "alphanumeric",
        cell: ({ row }) => {
            const {
                ruleName,
                subscriptionSilencedUntil: subscriptionSilenced,
            } = row.original
            return (
                <SubscriptionShowSilenceCell
                    cellValue={ruleName}
                    silenced={subscriptionSilenced}
                />
            )
        },
    }),
    columnHelper.accessor("metric", {
        id: METRIC_COLUMN_ID,
        header: "Metric",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("ruleDescription", {
        id: DESCRIPTION_COLUMN_ID,
        header: "Description",
        enableMultiSort: false,
        enableSorting: false,
    }),
    columnHelper.display({
        id: "menu",
        header: "Actions",
        cell: ({ row }) => {
            const { role } = useViewerContext().getViewer()
            const subscriptionId = row.original.id
            const activeSilence = Boolean(
                row.original.subscriptionSilencedUntil
            )

            return (
                <ActionMenu
                    menuOptions={getMySubscriptionsMenuByRole(
                        role,
                        subscriptionId,
                        activeSilence
                    )}
                />
            )
        },
    }),
    columnHelper.accessor("subscribedWells", {
        id: SUBSCRIBED_WELLS_COLUMN_ID,
        header: "",
        cell: () => {
            return <></>
        },
        filterFn: "includesStringSensitive",
        enableHiding: true,
    }),
]
