import { setContext } from "@apollo/client/link/context"
import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    from,
    NormalizedCacheObject,
} from "@apollo/client"
import { NGWebConfigHelper } from "./configuration/configuration"
import { User } from "oidc-client-ts"

const getBearerToken = (config: NGWebConfigHelper) => {
    // implementation per react-oidc-context for non child component of AuthProvider.
    const oidcStorage = localStorage.getItem(config.oidcStorageKey)
    if (!oidcStorage) {
        return null
    }
    const user = User.fromStorageString(oidcStorage)
    return `Bearer ${user.access_token}`
}

export const CreateClient = async (
    config: NGWebConfigHelper
): Promise<ApolloClient<NormalizedCacheObject>> => {
    const httpLink = new HttpLink({
        uri: `${config.graphqlEndpointURL}`,
    })

    const authLink = setContext(async (_, { headers }) => {
        const token = getBearerToken(config)
        return {
            headers: {
                ...headers,
                authorization: token,
            },
        }
    })

    const allLinks = from([authLink, httpLink])

    const client = new ApolloClient({
        link: allLinks,
        cache: new InMemoryCache({
            possibleTypes: {
                WellControlSetting: [
                    "WellControlSettingConstantIdle",
                    "WellControlSettingOff",
                    "WellControlSettingOn",
                    "WellControlSettingTimer",
                    "WellControlSettingUnspecified",
                ],
                WellState: [
                    "WellControlComplianceState",
                    "WellControlObservationState",
                ],
                WellAlert: [
                    "CommunicationsAlert",
                    "ControlAlert",
                    "InfoAlert",
                    "PowerLossAlert",
                    "SensorAlert",
                ],
            },
        }),
        connectToDevTools: true,
    })

    return client
}
