import { FunctionComponent, useEffect, useState } from "react"
import styles from "./individualWellPage.module.scss"
import IwpSubNavigation from "../../components/individualWellPageComponents/subNavigation"
import { IwpLineChartI } from "../../components/individualWellPageComponents/wellDetailsChart"
import { ApolloError, useQuery } from "@apollo/client"
import { INDIVIDUAL_WELL_PAGE_QUERY } from "../../graphql/queries/individualWellPage"
import { Outlet, useParams } from "react-router-dom"
import { getFragmentData } from "../../generated"
import { DATA_FRAME_FIELDS_FRAGMENT } from "../../graphql/fragments/dataFrame"
import {
    ControlSettingFieldsFragment,
    DataFrameFieldsFragment,
    DataPointFieldsFragment,
    InstallationFieldsFragment,
    WellAlertFieldsFragment,
    WellControlObservationStateFieldsFragment,
    WellIdentificationFieldsFragment,
} from "../../generated/graphql"
import { getVariableDaysAgo_StartOfDayUnixMs } from "../../util/datesAndTimes/datesAndTimes"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../graphql/fragments/wellIdentification"
import { DATA_POINT_FIELDS_FRAGMENT } from "../../graphql/fragments/dataPoint"
import { WELL_ALERT_FIELDS_FRAGMENT } from "../../graphql/fragments/wellAlert"
import {
    WELL_CONTROL_OBSERVATION_STATE_FIELDS_FRAGMENT,
    WELL_CONTROL_STATE_FIELDS_FRAGMENT,
} from "../../graphql/fragments/wellState"
import { CONTROL_SETTING_FIELDS_FRAGMENT } from "../../graphql/fragments/wellControlSetting"
import StatusSideBar from "../../components/individualWellPageComponents/statusSideBar"
import classNames from "classnames"
import { WellAttributesComponentI } from "../../components/individualWellPageComponents/wellAttributes"
import { INSTALLATION_FIELDS_FRAGMENT } from "../../graphql/fragments/installation"

export interface IwpOutletContextI {
    wellDetails: IwpLineChartI
    wellAttributes: WellAttributesComponentI
    loading: boolean
    error?: ApolloError
}

// These variables are exported so they can be used
// as the variables in refetchQueries for unlinking & linking modals
export const iwpQueryParam_equipStatsFrom =
    getVariableDaysAgo_StartOfDayUnixMs(92)
export const iwpQueryParam_equipStatsTo = getVariableDaysAgo_StartOfDayUnixMs(0)
export const iwpQueryParam_historyCount = 4

const IndividualWellPage: FunctionComponent = () => {
    const { wellId } = useParams()
    if (!wellId) throw new Error("wellId not resolved from url.")

    /** UI State variables */
    const initialBrowserWidth = window.innerWidth
    const [displayWellDetailsChart, setDisplayWellDetailsChart] = useState(true)
    const [displayStatusSideBar, setDisplayStatusSideBar] = useState(
        initialBrowserWidth > 992 ? true : false
    )
    const [windowSize, setWindowSize] = useState({
        width: initialBrowserWidth,
    })
    const [iwpFetchRangeFrom, setIwpFetchRangeFrom] = useState<string>("")

    /** Query individual well */
    const {
        error: iwpQueryError,
        data: iwpQueryData,
        loading: iwpQueryLoading,
        refetch: refetchIwp,
    } = useQuery(INDIVIDUAL_WELL_PAGE_QUERY, {
        variables: {
            wellInput: { id: wellId },
            equipStats_from: iwpQueryParam_equipStatsFrom,
            equipStats_to: iwpQueryParam_equipStatsTo,
            historyCount: iwpQueryParam_historyCount,
            wellControlInput: { wellID: wellId },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    })

    /** Parse Data fragments to pass to components */
    let runTimeDataFrame: DataFrameFieldsFragment | undefined = undefined
    let wellIdentificationfragment:
        | WellIdentificationFieldsFragment
        | undefined = undefined
    let runTimeDataPoint: DataPointFieldsFragment | undefined = undefined
    let latestSpmDataPoint: DataPointFieldsFragment | undefined = undefined
    let wellAlertFragment: WellAlertFieldsFragment | undefined | null =
        undefined
    let observationFragment:
        | WellControlObservationStateFieldsFragment
        | undefined = undefined

    let controlSettingFragment: ControlSettingFieldsFragment | undefined =
        undefined
    let installationFragment: InstallationFieldsFragment | null | undefined

    if (iwpQueryData && iwpQueryData.well) {
        const { well } = iwpQueryData
        /** Parse Fragments to pass to children components */
        runTimeDataFrame = getFragmentData(
            DATA_FRAME_FIELDS_FRAGMENT,
            well?.equipmentStats.windowedRuntime
        )

        wellIdentificationfragment = getFragmentData(
            WELL_IDENTIFICATION_FIELDS_FRAGMENT,
            well
        )
        runTimeDataPoint = getFragmentData(
            DATA_POINT_FIELDS_FRAGMENT,
            well.equipmentStats.dailyRuntime
        )

        latestSpmDataPoint = getFragmentData(
            DATA_POINT_FIELDS_FRAGMENT,
            well.equipmentStats.latestSpm
        )
        wellAlertFragment = getFragmentData(
            WELL_ALERT_FIELDS_FRAGMENT,
            well.alert
        )
        const WellControlStateFieldsFragment = getFragmentData(
            WELL_CONTROL_STATE_FIELDS_FRAGMENT,
            well.state
        )
        observationFragment = getFragmentData(
            WELL_CONTROL_OBSERVATION_STATE_FIELDS_FRAGMENT,
            WellControlStateFieldsFragment.observation
        )
        controlSettingFragment = getFragmentData(
            CONTROL_SETTING_FIELDS_FRAGMENT,
            well.controlSetting
        )
        installationFragment = getFragmentData(
            INSTALLATION_FIELDS_FRAGMENT,
            well.installation
        )
    }

    /** Effect hooks */
    useEffect(() => {
        if (windowSize.width > 992) {
            setDisplayWellDetailsChart(true)
        }
        if (windowSize.width < 992 && displayWellDetailsChart) {
            setDisplayStatusSideBar(false)
        }
    }, [windowSize.width])

    useEffect(() => {
        const handleWindowWidthChange = () => {
            setWindowSize({
                width: window.innerWidth,
            })
        }

        window.addEventListener("resize", handleWindowWidthChange)

        return () => {
            window.removeEventListener("resize", handleWindowWidthChange)
        }
    }, [])

    /** Handlers */
    const handleToggleStatusSideBar = () => {
        if (windowSize.width > 992) {
            setDisplayStatusSideBar(!displayStatusSideBar)
            setDisplayWellDetailsChart(true)
        }
        if (windowSize.width < 992) {
            const currentSideBarStatus = displayStatusSideBar
            setDisplayStatusSideBar(!currentSideBarStatus)
            setDisplayWellDetailsChart(currentSideBarStatus)
        }
    }

    const handleFetchRangeFromInputChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { value } = e.target
        // set value of input
        setIwpFetchRangeFrom(value)
        //refetch iwp query
        refetchIwp({
            equipStats_from: getVariableDaysAgo_StartOfDayUnixMs(
                parseInt(e.target.value)
            ),
        })
    }

    return (
        <>
            <div className={styles.container}>
                <IwpSubNavigation
                    toogleStatusBar={handleToggleStatusSideBar}
                    wellIdentificationfragment={wellIdentificationfragment}
                    windowWidth={windowSize.width}
                />
                <div className={styles.bodyContainer}>
                    {displayStatusSideBar && (
                        <div className={styles.sidebarContainer}>
                            <StatusSideBar
                                loading={iwpQueryLoading}
                                error={iwpQueryError}
                                wellIdentificationFragment={
                                    wellIdentificationfragment
                                }
                                runTimeDataPoint={runTimeDataPoint}
                                latestSpmDataPoint={latestSpmDataPoint}
                                wellAlertFragment={wellAlertFragment}
                                observationFragment={observationFragment}
                                controlSetting={controlSettingFragment}
                            />
                        </div>
                    )}
                    {displayWellDetailsChart && (
                        <div
                            className={classNames(
                                styles.iwpOutletContainer,
                                displayStatusSideBar &&
                                    styles.iwpSideBarContainerActive
                            )}
                        >
                            <Outlet
                                context={
                                    {
                                        wellDetails: {
                                            spmData: undefined,
                                            runTimeData: runTimeDataFrame,
                                            iwpFetchRangeFrom:
                                                iwpFetchRangeFrom,
                                            handleFetchRangeFromInputChange:
                                                handleFetchRangeFromInputChange,
                                        },
                                        wellAttributes: {
                                            wellIdentificationFragment:
                                                wellIdentificationfragment,
                                            installationFragment:
                                                installationFragment,
                                            windowWidth: windowSize.width,
                                        },
                                        loading: iwpQueryLoading,
                                        error: iwpQueryError,
                                    } satisfies IwpOutletContextI
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default IndividualWellPage
