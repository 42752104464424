import { graphql } from "../../generated"

export const SUBJECT_FIELDS_FRAGMENT = graphql(`
    fragment SubjectFields on Subject {
        id
        role
        identity {
            ...IdentityFields
        }
    }
`)

export const SUBJECT_FRAG_FOR_TRANSFER_SUB_TO_USER = graphql(`
    fragment SubjectFragForTransferSubToUser on Subject {
        id
        identity {
            fullName
        }
        organization {
            id
        }
    }
`)
