import { ReactNode, FunctionComponent } from "react"
import { Outlet } from "react-router-dom"
import { useQuery } from "@apollo/client"
import {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../shared/graphQlResponse"
import styles from "./subscriptionsByUser.module.scss"
import { getFragmentData } from "../../../../../generated"
import { SUBSCRIPTION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/subscription"
import { GET_SUBSCRIPTIONS_FOR_ORG } from "../../../../../graphql/queries/subscriptionsForOrg"
import { getSubscriptionsAdminTableFromSubscriptionsFrag } from "../../subscriptions_GraphQL/subscriptionsFragment"
import SubscriptionsByUserTable from "./table/table"
import { useNotificationsContext } from "../../../context/notificationsContext"
import SubscriptionsEmptyState from "../../sharedComponents/table/emptyState"

const SubscriptionsByUser: FunctionComponent = () => {
    const selectedOrganization = useNotificationsContext().selectedOrganization
    const { error, loading, data } = useQuery(GET_SUBSCRIPTIONS_FOR_ORG, {
        variables: {
            input: {
                orgIDs: [selectedOrganization.value],
            },
        },
        fetchPolicy: "network-only",
    })

    let body: ReactNode = <></>
    if (error) {
        body = (
            <ErrorMessage
                outSideDivStyle={styles.error}
                message="There was a problem fetching subscriptions by user."
            />
        )
    }
    if (loading) {
        body = <SyncLoaderComponent outSideDivStyle={styles.loader} />
    }
    if (data) {
        const subscriptions = data.subscriptionsForOrgs.subscriptions
        const formatted = getSubscriptionsAdminTableFromSubscriptionsFrag(
            subscriptions.map((sub) =>
                getFragmentData(SUBSCRIPTION_FIELDS_FRAGMENT, sub)
            )
        )

        if (formatted.length === 0) {
            body = (
                <SubscriptionsEmptyState
                    viewerAction="admin"
                    message="No subscriptions found"
                />
            )
        } else {
            body = (
                <SubscriptionsByUserTable
                    key={selectedOrganization.value}
                    data={formatted}
                />
            )
        }
    }

    return (
        <>
            <Outlet />
            <div className={styles.container}>{body}</div>
        </>
    )
}
export default SubscriptionsByUser
