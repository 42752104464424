import { graphql } from "../../generated"

export const UPDATE_USERS = graphql(`
    mutation UpdateUsers($UserUpdateInput: UserUpdateInput!) {
        userUpdate(input: $UserUpdateInput) {
            user {
                ...SubjectFields
            }
        }
    }
`)
