import { FunctionComponent, useContext } from "react"
import styles from "./userProfileSettings.module.scss"
import { createSearchParams, Outlet, useNavigate } from "react-router-dom"
import {
    PreferencesRow,
    PreferencesRowActions,
    PreferencesRowLabel,
    PreferencesRowValue,
    PreferencesSection,
    UserProfileInfoContainer,
    UserProfileInfoRow,
    UserProfileNotificationContainer,
    UserProfileSectionTitleRow,
} from "./settingsSection"
import { useMutation, useQuery } from "@apollo/client"
import { ConfigContext } from "../../context/ConfigContexts"
import { useViewerContext } from "../../context/ViewerContext"
import { SEND_CODE } from "../../graphql/mutations/verify"
import { AttributeType, SubjectRole } from "../../generated/graphql"
import { RolesUiDisplayObj } from "../../util/subjectRole"
import Button from "../../components/shared/button"
import {
    USER_SETTINGS_EDIT_DND_ABS_ROUTE,
    USER_SETTINGS_EDIT_EMAIL_ABS_ROUTE,
    USER_SETTINGS_EDIT_PHONE_NUMBER_ABS_ROUTE,
    USER_SETTINGS_EDIT_PROFILE_ABS_ROUTE,
    USER_SETTINGS_EDIT_TIMEZONE_ABS_ROUTE,
    USER_SETTINGS_VERIFY_ABS_ROUTE,
} from "../.."
import { getDisplayStringFromZoneInfo } from "../../graphql/enum/zoneInfo"
import { GET_USER_DND_SETTINGS_BY_SUBJECT_ID } from "../../graphql/queries/userSettings/dndSettings"
import {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../components/shared/graphQlResponse"
import { getFragmentData } from "../../generated"
import { GET_USER_SETTINGS_DND_FRAGMENT } from "../../graphql/fragments/userSettingsDnD"
import { TimeOfDayToDisplayString } from "../../graphql/clock/timeOfDay"
import { useAppAlertBanner } from "../../context/alertBannerContext"
import ToolTip from "../../components/shared/toolTip"

const UserProfileSettings: FunctionComponent = () => {
    const navigate = useNavigate()
    const config = useContext(ConfigContext)
    const { getViewer } = useViewerContext()
    const alertBannerContext = useAppAlertBanner()

    const { identity, role, emailConsent, smsConsent, userId, getPhoneNumber } =
        getViewer()

    if (!identity) {
        throw new Error(" Identity was undefined in  user profile settings")
    }

    // query dnd settings
    const { error, loading, data } = useQuery(
        GET_USER_DND_SETTINGS_BY_SUBJECT_ID,
        {
            variables: {
                getDndSettingsBySubjectIDInput: {
                    subjectIDs: [userId],
                },
            },
        }
    )

    const dndSetting = data?.dnDSettingBySubjectID.settings.length
        ? getFragmentData(
              GET_USER_SETTINGS_DND_FRAGMENT,
              data.dnDSettingBySubjectID.settings[0]
          )
        : undefined

    const [sendCodeMutation, { loading: mutationLoading }] =
        useMutation(SEND_CODE)

    let roleDisplay: string

    switch (role) {
        case SubjectRole.OrgAdmin:
            roleDisplay = RolesUiDisplayObj[SubjectRole.OrgAdmin]
            break
        case SubjectRole.PpoAdmin:
            roleDisplay = RolesUiDisplayObj[SubjectRole.PpoAdmin]
            break
        case SubjectRole.OrgMember:
            roleDisplay = RolesUiDisplayObj[SubjectRole.OrgMember]
            break
        case SubjectRole.Unknown:
            roleDisplay = RolesUiDisplayObj[SubjectRole.Unknown]
            break
        case SubjectRole.System:
            throw new Error(
                "pages/userProfileSettings - System should not be a user role"
            )
        case undefined:
            throw new Error("User profile settings page. Role was undefined")
    }

    const handleForgotPassword = () => {
        window.open(config.forgotPasswordURL, "_blank", "noreferrer")
    }

    const handleVerifyClick = async (attr: "email" | "sms") => {
        let attrType: AttributeType
        switch (attr) {
            case "email":
                attrType = AttributeType.Email
                break
            case "sms":
                attrType = AttributeType.Sms
                break
            default:
                throw Error(`invalid attribute: ${attr}`)
        }

        try {
            await sendCodeMutation({
                variables: {
                    SendVerifyCodeInput: {
                        type: attrType,
                    },
                },
            })
            navigate({
                pathname: USER_SETTINGS_VERIFY_ABS_ROUTE,
                search: createSearchParams({ attr: attr }).toString(),
            })
        } catch (error) {
            const t = attr === "email" ? "email" : "text"
            alertBannerContext.showAppAlert({
                message: (
                    <div className={styles.alertBannerMessage}>
                        Failed to send verification {t}. Please try again or
                        reach out to
                        <a
                            href="mailto:support@petropower.com"
                            className={styles.supportLink}
                        >
                            support@petropower.com
                        </a>
                        .
                    </div>
                ),
                alertType: "danger",
            })
        }
    }

    if (loading) {
        return <SyncLoaderComponent />
    }

    if (error) {
        return (
            <ErrorMessage message="There was a problem fetching your notification preferences." />
        )
    }

    // constants
    let emailWarning: string | undefined = undefined
    if (!identity.email) {
        emailWarning = "Warning: you are missing an email address"
    } else if (!identity.emailVerified) {
        emailWarning =
            "Warning: email is not verified and you cannot receive notifications."
    } else if (!identity.emailConsent) {
        emailWarning = "Click edit to allow email notifications."
    }

    let phoneWarning: string | undefined = undefined
    if (!identity.telephoneNumber) {
        phoneWarning = "Warning: you are missing a phone number"
    } else if (!identity.telephoneNumberVerified) {
        phoneWarning =
            "Warning: phone is not verified and you cannot receive notifications."
    } else if (!identity.smsConsent) {
        phoneWarning = "Click edit to allow text notifications."
    }

    return (
        <>
            <Outlet />
            <div className={styles.pageContainer}>
                <div className={styles.bodyContainer}>
                    <div className={styles.userProfileContainer}>
                        <UserProfileSectionTitleRow title="User Information">
                            <Button
                                handleButtonClick={() => {
                                    navigate(
                                        USER_SETTINGS_EDIT_PROFILE_ABS_ROUTE
                                    )
                                }}
                                status="primary"
                                condition="default"
                            >
                                Edit Profile
                            </Button>
                        </UserProfileSectionTitleRow>
                        <UserProfileInfoRow>
                            <UserProfileInfoContainer
                                heading="First Name"
                                value={identity.firstName}
                            />
                            <UserProfileInfoContainer
                                heading="Last Name"
                                value={identity.lastName}
                            />
                            <UserProfileInfoContainer
                                heading="Role"
                                value={roleDisplay}
                            />
                        </UserProfileInfoRow>
                        <UserProfileSectionTitleRow title="Notification Preferences" />
                        <UserProfileNotificationContainer>
                            <PreferencesSection title="Email">
                                <PreferencesRow>
                                    <PreferencesRowLabel label="Email:" />
                                    <ToolTip
                                        orientation="top"
                                        toolTipChildren={identity.email}
                                        customToolTipTriggerClass={
                                            styles.emailToolTipTrigger
                                        }
                                    >
                                        <div
                                            className={
                                                styles.emailPreferencesRowValue
                                            }
                                        >
                                            {identity.email}
                                        </div>
                                    </ToolTip>
                                    <PreferencesRowValue>
                                        {identity.emailVerified ? (
                                            <div className={styles.dndActive}>
                                                verified
                                            </div>
                                        ) : (
                                            <div className={styles.dndInactive}>
                                                unverified
                                            </div>
                                        )}
                                    </PreferencesRowValue>
                                </PreferencesRow>
                                <PreferencesRow>
                                    <PreferencesRowLabel label="Allow Notifications:" />
                                    <PreferencesRowValue>
                                        {emailConsent ? (
                                            <div className={styles.dndActive}>
                                                yes
                                            </div>
                                        ) : (
                                            <div className={styles.dndInactive}>
                                                no
                                            </div>
                                        )}
                                    </PreferencesRowValue>
                                </PreferencesRow>
                                <PreferencesRowActions>
                                    <div className={styles.emailWarning}>
                                        {emailWarning ? emailWarning : null}
                                    </div>
                                    <div className={styles.flexRow}>
                                        {!identity.emailVerified ? (
                                            <Button
                                                status="secondary"
                                                handleButtonClick={() =>
                                                    handleVerifyClick("email")
                                                }
                                                disabled={mutationLoading}
                                            >
                                                Verify
                                            </Button>
                                        ) : null}
                                        <Button
                                            handleButtonClick={() => {
                                                navigate(
                                                    USER_SETTINGS_EDIT_EMAIL_ABS_ROUTE
                                                )
                                            }}
                                            status="primary"
                                            condition="default"
                                            customButtonClassName={
                                                styles.editButton
                                            }
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </PreferencesRowActions>
                            </PreferencesSection>
                            <PreferencesSection title="Text">
                                <PreferencesRow>
                                    <PreferencesRowLabel label="Phone Number:" />
                                    <PreferencesRowValue>
                                        {getPhoneNumber(true) ||
                                            "(###) ###-####"}
                                    </PreferencesRowValue>
                                    <PreferencesRowValue>
                                        {identity.telephoneNumberVerified ? (
                                            <div className={styles.dndActive}>
                                                verified
                                            </div>
                                        ) : (
                                            <div className={styles.dndInactive}>
                                                unverified
                                            </div>
                                        )}
                                    </PreferencesRowValue>
                                </PreferencesRow>
                                <PreferencesRow>
                                    <PreferencesRowLabel label="Allow Notifications:" />
                                    <PreferencesRowValue>
                                        {smsConsent ? (
                                            <div className={styles.dndActive}>
                                                yes
                                            </div>
                                        ) : (
                                            <div className={styles.dndInactive}>
                                                no
                                            </div>
                                        )}
                                    </PreferencesRowValue>
                                </PreferencesRow>
                                <PreferencesRowActions>
                                    <div className={styles.emailWarning}>
                                        {phoneWarning ? phoneWarning : null}
                                    </div>
                                    <div className={styles.flexRow}>
                                        {!identity.telephoneNumberVerified &&
                                        getPhoneNumber() ? (
                                            <Button
                                                status="secondary"
                                                handleButtonClick={() =>
                                                    handleVerifyClick("sms")
                                                }
                                                disabled={mutationLoading}
                                            >
                                                Verify
                                            </Button>
                                        ) : null}
                                        <Button
                                            handleButtonClick={() => {
                                                navigate(
                                                    USER_SETTINGS_EDIT_PHONE_NUMBER_ABS_ROUTE
                                                )
                                            }}
                                            status="primary"
                                            condition="default"
                                            customButtonClassName={
                                                styles.editButton
                                            }
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </PreferencesRowActions>
                            </PreferencesSection>
                            <PreferencesSection title="Text Notifications - Do Not Disturb Hours">
                                <PreferencesRow>
                                    <PreferencesRowLabel label="Do Not Disturb Schedule:" />
                                    <PreferencesRowValue>
                                        {dndSetting?.active ? (
                                            <div className={styles.dndActive}>
                                                active
                                            </div>
                                        ) : (
                                            <div className={styles.dndInactive}>
                                                inactive
                                            </div>
                                        )}
                                    </PreferencesRowValue>
                                </PreferencesRow>
                                <PreferencesRow>
                                    <PreferencesRowLabel label="Start Time:" />
                                    <PreferencesRowValue specialType="time">
                                        {dndSetting
                                            ? TimeOfDayToDisplayString(
                                                  dndSetting.fromTime,
                                                  false
                                              )
                                            : "00:00"}
                                    </PreferencesRowValue>
                                </PreferencesRow>
                                <PreferencesRow>
                                    <PreferencesRowLabel label="End Time:" />
                                    <PreferencesRowValue specialType="time">
                                        {dndSetting
                                            ? TimeOfDayToDisplayString(
                                                  dndSetting.toTime,
                                                  false
                                              )
                                            : "00:00"}
                                    </PreferencesRowValue>
                                </PreferencesRow>
                                <PreferencesRowActions>
                                    <Button
                                        handleButtonClick={() => {
                                            navigate(
                                                USER_SETTINGS_EDIT_DND_ABS_ROUTE
                                            )
                                        }}
                                        status="primary"
                                        condition="default"
                                        customButtonClassName={
                                            styles.editButton
                                        }
                                    >
                                        Edit
                                    </Button>
                                </PreferencesRowActions>
                            </PreferencesSection>
                            <PreferencesSection>
                                <PreferencesRow>
                                    <PreferencesRowLabel label="Time Zone:" />
                                    <PreferencesRowValue>
                                        {getDisplayStringFromZoneInfo(
                                            identity.zoneInfo
                                        )}
                                    </PreferencesRowValue>
                                </PreferencesRow>
                                <PreferencesRowActions>
                                    <Button
                                        handleButtonClick={() => {
                                            navigate(
                                                USER_SETTINGS_EDIT_TIMEZONE_ABS_ROUTE
                                            )
                                        }}
                                        status="primary"
                                        condition="default"
                                    >
                                        Edit
                                    </Button>
                                </PreferencesRowActions>
                            </PreferencesSection>
                        </UserProfileNotificationContainer>
                        <div className={styles.disclaimerText}>
                            By using the T-Rex Notification service, you agree
                            to our use of these tools in accordance with our
                            Privacy Policy and you agree to the terms of our
                            Terms of Service.
                        </div>
                    </div>
                    <div className={styles.passwordContainer}>
                        <div className={styles.passwordSubContainer}>
                            <div className={styles.passwordResetHeading}>
                                Password Reset
                            </div>
                            <Button
                                handleButtonClick={handleForgotPassword}
                                status="primary"
                                condition="default"
                            >
                                Change Password
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfileSettings
