import { graphql } from "../../generated"

export const TRANSFER_SUB_TO_USER_QRY = graphql(`
    query TransferToUserModals(
        $usersFirst: Int
        $filter: SubjectFilter
        $subscriptionInput: AlertSubscriptionsForIDsInput!
    ) {
        users(first: $usersFirst, filter: $filter) {
            nodes {
                ...SubjectFragForTransferSubToUser
            }
        }
        subscriptionsForIDs(input: $subscriptionInput) {
            subscriptions {
                ...SubscriptionFragForTransferSubToUser
            }
        }
    }
`)
