import { FunctionComponent } from "react"
import { AddWellsWellDataInterface } from "../../../../../types/addWellsTypes"
import styles from "./wellApiDataDisplay.module.scss"
import ToolTip from "../../../../shared/toolTip"

interface WellApiInfoDisplayPropsInterface {
    wellData: AddWellsWellDataInterface
}

const WellApiInfoDisplay: FunctionComponent<
    WellApiInfoDisplayPropsInterface
> = ({ wellData }) => {
    const displayWellDataPrompt = {
        apiNumber: "Api#",
        WellName: "Well Name",
        Latitude: "Latitude",
        Longitude: "Longitude",
        addWellModalId: "addWellModalId",
    }

    const displayWellDataValues = {
        apiNumber: wellData.WellAttributesForm.apiNumber || "N/A",
        WellName: wellData.WellAttributesForm.WellName,
        Latitude: wellData.WellAttributesForm.Latitude,
        Longitude: wellData.WellAttributesForm.Longitude,
        addWellModalId: wellData.addWellModalId,
    }

    const previewRows = ["WellName", "Latitude", "Longitude"]

    const wellApiInformation = previewRows.map((rowKey, index) => {
        const key = rowKey as keyof typeof wellData.WellAttributesForm
        const value = displayWellDataValues[key]
        const prompt = displayWellDataPrompt[key]

        const row = (
            <div key={index} className={styles.containerRow}>
                <div className={styles.textHeading}>{prompt}:</div>
                {key === "WellName" ? (
                    <div className={styles.toolTipContainer}>
                        <ToolTip orientation="topLeft" toolTipChildren={value}>
                            <div className={styles.subtext}>{value}</div>
                        </ToolTip>
                    </div>
                ) : (
                    <div className={styles.subtext}>{value}</div>
                )}
            </div>
        )

        return row
    })

    return (
        <div>
            <div className={styles.container}>{wellApiInformation}</div>
        </div>
    )
}

export default WellApiInfoDisplay
