import { graphql } from "../../generated"

export const SEND_CODE = graphql(`
    mutation SendCode($SendVerifyCodeInput: SendCodeInput!) {
        sendCode(input: $SendVerifyCodeInput) {
            contact
        }
    }
`)

export const VERIFY_CODE = graphql(`
    mutation VerifyCode($VerifyCodeInput: VerifyCodeInput!) {
        verifyCode(input: $VerifyCodeInput) {
            verified
        }
    }
`)

export const UPDATE_CONSENT = graphql(`
    mutation UpdateConsent(
        $ConsentUpdateInput: NotificationConsentUpdateInput!
    ) {
        consentUpdate(input: $ConsentUpdateInput) {
            consent
        }
    }
`)
