import { FunctionComponent } from "react"
import { SubjectRole } from "../../../generated/graphql"
import { useViewerContext } from "../../../context/ViewerContext"
import Input from "../../shared/input"
import styles from "./IwpRangeSelector.module.scss"
import { canRoleFetchCustomRangeForIwp } from "../../../util/rolePermissions/canRole"

interface IwpRangeSelector {
    iwpFetchRangeFrom: string
    handleFetchRangeFromInputChange: (
        e: React.ChangeEvent<HTMLSelectElement>,
        userRole: SubjectRole
    ) => void
}

const IwpRangeSelector: FunctionComponent<IwpRangeSelector> = ({
    iwpFetchRangeFrom,
    handleFetchRangeFromInputChange,
}) => {
    // hooks
    const viewer = useViewerContext()
    const { role: userRole } = viewer.getViewer()

    const renderInputComponent = canRoleFetchCustomRangeForIwp(userRole)

    if (renderInputComponent) {
        return (
            <div className={styles.container}>
                <Input
                    type="select"
                    onChange={(e) => {
                        handleFetchRangeFromInputChange(e, userRole)
                    }}
                    options={[
                        {
                            label: "3 Months",
                            value: "92",
                        },
                        { label: "6 Months", value: "180" },
                        { label: "1 year", value: "365" },
                        // 1826 is 5 years worth of data.
                        { label: "All", value: "1826" },
                    ]}
                    orientation="vertical"
                    value={iwpFetchRangeFrom}
                />
            </div>
        )
    } else {
        return <></>
    }
}

export default IwpRangeSelector
