import React, { FunctionComponent } from "react"
import { useViewerContext } from "../../../context/ViewerContext"
import Select, { StylesConfig, GroupBase } from "react-select"
import { useAddWellsContext } from "../../../context/addWellsContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faCircleInfo } from "@fortawesome/pro-solid-svg-icons"
import { Checkbox } from "../../shared/selectionControls"
import Input from "../../shared/input"
import styles from "./wellApiForm.module.scss"
import { OrganizationOptionType } from "../../../types/ViewerTypes"
import ToolTip from "../../shared/toolTip"
import { formatWellApiNumberInput } from "../../../util/wellAttributes/formatWellApiNumberHelper"

const WellApiNumberForm: FunctionComponent = () => {
    // context
    const {
        handleSetAddWellOrganization,
        addWellsState: { wellApiNumberFormState, selectedOrganization },
        handleWellApiNumberChange,
        handleWellApiNumberInputBlur,
        handleSubmitWellWithNoApiNumber,
        handleSubmitWellApiNumberForm,
    } = useAddWellsContext()

    const { getViewer } = useViewerContext()
    const { organizationAndDescendants } = getViewer()

    /** Handlers  */
    /** Api number change  */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedWellApiNumber = formatWellApiNumberInput(e)
        handleWellApiNumberChange(formattedWellApiNumber)
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <Input
                        onChange={handleInputChange}
                        handleClickNestedLabel={handleSubmitWellApiNumberForm}
                        onBlur={handleWellApiNumberInputBlur}
                        value={wellApiNumberFormState.wellApiNumber}
                        orientation="horizontal"
                        label="Enter API #"
                        type="text"
                        inputCustomClass="add-well-api-search-custom-class"
                        disabled={
                            wellApiNumberFormState.disableWellApiNumberInput
                        }
                        inputLabelRight={
                            <FontAwesomeIcon icon={faPlus} color="#fff" />
                        }
                        customNestedLabelClass="add-well-api-search-fai"
                        message={
                            wellApiNumberFormState.wellApiNumberErrMsg
                                ? wellApiNumberFormState.wellApiNumberErrMsg
                                : ""
                        }
                        specializedClass={
                            wellApiNumberFormState.wellApiNumberErrMsg
                                ? "danger"
                                : "default"
                        }
                    />
                    <div className={styles.leftContainerText}>
                        API # must have 10 numbers (ex: xx-xxx-xxxxx) or 14
                        numbers (ex: xx-xxx-xxxxx-xx-xx)
                    </div>
                    {true && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "16px",
                            }}
                        >
                            <div className={styles.organizationLabel}>
                                Organization *
                            </div>
                            <div>
                                <Select
                                    options={organizationAndDescendants}
                                    isSearchable={true}
                                    onChange={handleSetAddWellOrganization}
                                    value={selectedOrganization}
                                    styles={reactSelectStyles}
                                    placeholder={"- Select -"}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.rightContainer}>
                    <div>
                        <Checkbox
                            checked={wellApiNumberFormState.noApiNumberCheckBox}
                            handleCheckBoxClick={
                                handleSubmitWellWithNoApiNumber
                            }
                            checkboxLabel="Well Has No API #"
                            disabled={
                                wellApiNumberFormState.disableNoApiNumberCheckbox
                            }
                        />
                    </div>
                    <div style={{ paddingLeft: "8px", marginTop: "2px" }}>
                        <ToolTip
                            orientation="bottom"
                            toolTipChildren={
                                <>
                                    If your well does not have an assigned API
                                    #, please select this option. PetroPower
                                    will then assign a placeholder API #
                                    automatically.
                                </>
                            }
                        >
                            <FontAwesomeIcon
                                color="#9e9e9e"
                                icon={faCircleInfo}
                            />
                        </ToolTip>
                    </div>
                </div>
            </div>
        </>
    )
}

const reactSelectStyles: StylesConfig<
    OrganizationOptionType,
    false,
    GroupBase<OrganizationOptionType>
> = {
    control: (provided, state) => ({
        ...provided,
        boxSizing: "border-box",
        outline: "none",
        padding: ".0 .25rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: state.isFocused ? "#64b5f6" : "#cfd8dc",
        borderRadius: "4px",
        backgroundColor: "white",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#37474f",
        height: "32px",
        boxShadow: state.isFocused
            ? "0px 0px 0px 3.2px rgba(0, 123, 255, 0.25)"
            : "none",
        width: "352px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? "#64b5f6"
            : state.isFocused
            ? "#f5f5f5"
            : "transparent",

        color: state.isSelected ? "white" : "inherit",
    }),
}

export default WellApiNumberForm
