import { FunctionComponent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { SUBSCRIPTION_ADD } from "../../../../../graphql/mutations/subscription_add"
import classNames from "classnames"
import styles from "./copySubscriptionToUser.module.scss"
import MutationErrorBanner from "../../../../shared/graphQlResponse"
import ModalFooter from "../../../../shared/modalFooter"
import Select from "react-select"
import { subscriptionsToAlertSubAddInput } from "../transferSubscriptionFormUtil/api"
import { GET_SUBSCRIPTIONS_FOR_ORG } from "../../../../../graphql/queries/subscriptionsForOrg"
import { useNotificationsContext } from "../../../context/notificationsContext"
import { navigateAfterMutation } from "../../../navigate"
import {
    CopySubscriptionFormT,
    TransferSubscriptionFormComponentI,
} from "../transferSubscriptionFormUtil/types"
import { baseReactSelectStyles } from "../../../../../styles/reactSelect"

const CopyToUserForm: FunctionComponent<TransferSubscriptionFormComponentI> = ({
    toUserOptions,
    subscriptions,
}) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const notificationsContext = useNotificationsContext()

    // state
    const [copyToUser, setCopyToUser] =
        useState<CopySubscriptionFormT>(undefined)
    const [mutationErrorBanner, setMutationErrorBanner] = useState(false)

    // mutation - to perform a subscription copy we take the subscription(s) from one user and add it to another
    const [subscriptionCopy, { loading: copySubscriptionLoading }] =
        useMutation(SUBSCRIPTION_ADD)

    // handlers
    const handleSubmitCopy = () => {
        if (!copyToUser) {
            // submit is disabled if no user is selected
            return
        }

        const addSubscriptionsInput = subscriptionsToAlertSubAddInput(
            subscriptions,
            copyToUser.value
        )

        subscriptionCopy({
            variables: {
                AlertSubscriptionsAddInput: { inputs: addSubscriptionsInput },
            },
            // refetch the subscriptions for the org and user to update the cache
            refetchQueries: [
                {
                    query: GET_SUBSCRIPTIONS_FOR_ORG,
                    variables: {
                        input: {
                            orgIDs: [
                                notificationsContext.selectedOrganization.value,
                            ],
                        },
                    },
                },
            ],
        })
            .then(() => {
                navigate(navTo.to, navTo.options)
            })
            .catch((e) => {
                console.error(e)
                setMutationErrorBanner(true)
            })
    }

    // constants
    const navTo = navigateAfterMutation(pathname)
    const subscriptionsCount = subscriptions.length
    const filteredToUserOptions = toUserOptions.filter((toUser) => {
        // check if the subscription is already subscribed to the user
        return (
            subscriptions.findIndex(
                (s) => s.subscriber.subscriberId === toUser.value
            ) === -1
        )
    })

    return (
        <>
            <div
                className={classNames(
                    styles.bodyContainer,
                    mutationErrorBanner && styles.bodyContainerError
                )}
            >
                <div className={classNames(styles.row, styles.bodyText)}>
                    {subscriptionsCount} subscriptions will be
                    <span className={styles.bodyBoldText}> copied to</span> the
                    below user:
                </div>
                <Select
                    options={filteredToUserOptions}
                    onChange={(e) => {
                        if (!e) {
                            setCopyToUser(undefined)
                        }
                        if (e) {
                            setCopyToUser({ label: e?.label, value: e?.value })
                        }
                    }}
                    value={copyToUser}
                    isSearchable={true}
                    isDisabled={false}
                    isClearable={true}
                    placeholder={"Select User"}
                    styles={baseReactSelectStyles}
                />
            </div>
            {mutationErrorBanner && (
                <div className={styles.errorBanner}>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was problem copying subscriptions to the
                                new user. Click{" "}
                                <u
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigate(-1)}
                                >
                                    here
                                </u>{" "}
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </div>
            )}
            <ModalFooter
                advanceText="Save"
                disableAdvance={
                    copySubscriptionLoading ||
                    mutationErrorBanner ||
                    !copyToUser
                }
                handleAdvanceClick={handleSubmitCopy}
            />
        </>
    )
}

export default CopyToUserForm
