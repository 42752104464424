import { createColumnHelper } from "@tanstack/react-table"
import { AlertRulesTableDataI } from "../tableTypes"
import { useViewerContext } from "../../../../../context/ViewerContext"
import { ActionMenu } from "../../../../shared/tanStack/cell/actionsMenu"
import { getAlertRuleMenuByRole } from "../../../../../util/rolePermissions/getByRole"
import { Link } from "react-router-dom"
import { SubjectRole } from "../../../../../generated/graphql"

// constants

// Note: Constants are used to style table columns.
// If the column headers are changed in this file, they should be updated in: table.module.scss , header.module.scss
// ex: author_Cell => ruleAuthor_Cell
// Note: The column ids are used to keep the urlState in sync with the application state and the column id value will be reflected in the url
// example: ?author="Alice"

export const RULE_NAME_COLUMN_ID = "ruleName"
export const METRIC_COLUMN_ID = "metric"
export const DESCRIPTION_COLUMN_ID = "description"
export const AUTHOR_COLUMN_ID = "author"
export const MENU_COLUMN_ID = "menu"

// table columns
const columnHelper = createColumnHelper<AlertRulesTableDataI>()

export const alertRulesColumns = [
    columnHelper.accessor("ruleName", {
        id: RULE_NAME_COLUMN_ID,
        header: "Rule Name",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: "alphanumeric",
        cell: ({ row }) => {
            const viewer = useViewerContext().getViewer()
            const ruleSlug = [
                SubjectRole.PpoAdmin,
                SubjectRole.OrgAdmin,
            ].includes(viewer.role)
                ? "updateRule"
                : "viewRule"

            const { id, ruleName } = row.original

            return (
                <Link
                    style={{ color: "#1e88e5", textDecoration: "none" }}
                    to={`${id}/${ruleSlug}`}
                >
                    {ruleName}
                </Link>
            )
        },
    }),
    columnHelper.accessor("metric", {
        id: METRIC_COLUMN_ID,
        header: "Metric",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("description", {
        id: DESCRIPTION_COLUMN_ID,
        header: "Description",
        enableMultiSort: false,
        enableSorting: false,
        sortDescFirst: false,
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("ruleAuthor", {
        id: AUTHOR_COLUMN_ID,
        header: "Rule Author",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: "text",
    }),
    columnHelper.display({
        id: "menu",
        header: "Actions",
        cell: ({ row }) => {
            const { role } = useViewerContext().getViewer()
            const ruleId = row.original.id
            return (
                <ActionMenu
                    menuOptions={getAlertRuleMenuByRole(role, ruleId)}
                />
            )
        },
    }),
]
