import { SubjectRole } from "../../generated/graphql"

type ViewComponentType =
    | "InviteUser"
    | "AdminDashboard"
    | "AdminAddOrganization"
    | "AdminUpdateOrganization"
    | "UserSettings"
    | "AddWells"
    | "WellControlsEdit"
    | "WellControlsLink"
    | "WellControlsUnlink"
    | "WellIndex"
    | "WellDetails"
    | "WellAttributes"
    | "UpdateWellAttributes"
    | "UpdateWellWiringMode"
    | "Notifications"
    | "SubscriptionsByRule"
    | "SubscriptionsByUser"
    | "UpdateRule"
    | "AddRule"
    | undefined

type ComponentMappingArrayType = {
    path: string
    component: ViewComponentType
}[]

class ViewComponentPermissions {
    private _role: SubjectRole
    private _urlPathName: string | undefined

    constructor(role: SubjectRole, urlPathName?: string) {
        this._role = role
        this._urlPathName = urlPathName
    }

    private urlToViewComponent(
        urlPathName: string | undefined
    ): ViewComponentType | undefined {
        if (!urlPathName) {
            return undefined
        }

        /** Ordering is important  */
        if (urlPathName.endsWith("/inviteUser")) {
            return "InviteUser"
        } else if (
            urlPathName.match(/^\/dashboard\/controls\/[\w+/=]+\/edit$/)
        ) {
            return "WellControlsEdit"
        } else if (
            urlPathName.match(/^\/dashboard\/controls\/[\w+/=]+\/link$/)
        ) {
            return "WellControlsLink"
        } else if (
            urlPathName.match(/^\/dashboard\/controls\/[\w+/=]+\/unlink$/)
        ) {
            return "WellControlsUnlink"
        }
        // for indiviudal well page modals
        else if (urlPathName.endsWith("/controls/edit")) {
            return "WellControlsEdit"
        } else if (urlPathName.endsWith("/controls/link")) {
            return "WellControlsLink"
        } else if (urlPathName.endsWith("/controls/unlink")) {
            return "WellControlsUnlink"
        } else if (urlPathName.match(/^\/wellAttributes\/[\w+/=]+\/edit$/)) {
            return "UpdateWellAttributes"
        } else if (
            urlPathName.match(/^\/wellAttributes\/[\w+/=]+\/editInstallation$/)
        ) {
            return "UpdateWellWiringMode"
        }
        // for specific admin pages
        else if (urlPathName.endsWith("/addOrganization")) {
            return "AdminAddOrganization"
        } else if (
            urlPathName.match(/^\/admin\/organizations\/[\w+/=]+\/update$/)
        ) {
            return "AdminUpdateOrganization"
        }
        // specific notifications pages
        else if (urlPathName.includes("/subscriptionsByRule")) {
            return "SubscriptionsByRule"
        } else if (urlPathName.includes("/subscriptionsByUser")) {
            return "SubscriptionsByUser"
        } else if (urlPathName.endsWith("updateRule")) {
            return "UpdateRule"
        } else if (urlPathName.endsWith("addRule")) {
            return "AddRule"
        }

        const componentMappings: ComponentMappingArrayType = [
            { path: "/admin", component: "AdminDashboard" },
            { path: "/userSettings", component: "UserSettings" },
            { path: "/dashboard/addWells", component: "AddWells" },
            { path: "/dashboard", component: "WellIndex" },
            { path: "/wellDetails", component: "WellDetails" },
            { path: "/wellAttributes", component: "WellAttributes" },
            { path: "/userSettings", component: "UserSettings" },
            { path: "/notifications", component: "Notifications" },
            { path: "/n/m", component: "Notifications" },
        ]

        for (const mapping of componentMappings) {
            if (urlPathName.startsWith(mapping.path)) {
                return mapping.component
            }
        }

        return undefined
    }

    private canReadComponentByRole(component: ViewComponentType): boolean {
        if (component === undefined) return false

        switch (this._role) {
            case SubjectRole.PpoAdmin:
                return true
            case SubjectRole.OrgAdmin: {
                const availableComponents: ViewComponentType[] = [
                    "AdminDashboard",
                    "InviteUser",
                    "UserSettings",
                    "AddWells",
                    "WellControlsEdit",
                    "WellIndex",
                    "WellControlsLink",
                    "WellControlsUnlink",
                    "WellDetails",
                    "WellAttributes",
                    "UpdateWellAttributes",
                    "UpdateWellWiringMode",
                    "Notifications",
                    "SubscriptionsByRule",
                    "SubscriptionsByUser",
                    "UpdateRule",
                    "AddRule",
                ]

                return availableComponents.includes(component)
            }
            case SubjectRole.OrgMember: {
                const availableComponents: ViewComponentType[] = [
                    "UserSettings",
                    "WellControlsEdit",
                    "WellIndex",
                    "WellDetails",
                    "WellAttributes",
                    "Notifications",
                ]

                return availableComponents.includes(component)
            }
            default:
                return false
        }
    }

    public canReadComponent(): boolean {
        const viewComponent = this.urlToViewComponent(this._urlPathName)

        if (viewComponent === undefined || this._role === SubjectRole.Unknown) {
            return false
        }

        return this.canReadComponentByRole(viewComponent)
    }
}

export { ViewComponentPermissions }
