import React, { FunctionComponent } from "react"
// components
import NavigationControls from "../../shared/navigationControls"
// scss
import styles from "./wellControlsHistory.module.scss"
// types
import {
    ControlSettingFieldsFragment,
    SubjectRole,
} from "../../../generated/graphql"
import {
    ControlSettingFieldsFragmentCustomUnionType,
    ControlSettingFragmentFieldsInterface,
} from "../../../types/controlSettingTypes"
// util
import { getWellControlSettingFragmentFieldsUnion } from "../../../util/ControlSettingUtil"
import { useWellControlsContext } from "../../../context/wellControlsContext"
import {
    convertUnixMillisecondsToLocalDate,
    convertUnixMillisecondsToLocalTime,
} from "../../../util/datesAndTimes/datesAndTimes"
import ToolTip from "../../shared/toolTip/index"
import { useViewerContext } from "../../../context/ViewerContext"
import { getFragmentData } from "../../../generated"
import { IDENTITY_FIELDS_FRAGMENT } from "../../../graphql/fragments/Identity"

interface WellControlHistoryProps {
    history: ControlSettingFieldsFragment[]
    historyCount: number
}

const WellControlsHistory: FunctionComponent<WellControlHistoryProps> = ({
    history,
    historyCount,
}) => {
    const { wellControlState, handleSelectHistoryChange } =
        useWellControlsContext()
    const { getViewer } = useViewerContext()
    const { role: userRole } = getViewer()

    const { selectedHistoryIndex } = wellControlState.UiState

    // create history component navigational controls
    const historyNavigationControls = history.map((historyObj, index) => {
        const {
            runMode,
            controlSettingFragmentFields: { createdAt },
        } = getWellControlSettingFragmentFieldsUnion(historyObj)
        const condition =
            index === selectedHistoryIndex ? "selected" : "default"
        return (
            <div key={index}>
                <NavigationControls
                    disabled={false}
                    usage={"sub-nav"}
                    condition={condition}
                    handleClick={() => handleSelectHistoryChange(index)}
                    navControlsCustomClass="wellControlHisotryNavigation"
                >
                    <div className={styles.navControlRunModeText}>
                        {runMode}
                    </div>
                    <div>
                        {convertUnixMillisecondsToLocalDate(
                            createdAt.unixMilliseconds
                        )}
                    </div>
                </NavigationControls>
            </div>
        )
    })

    while (historyNavigationControls.length < historyCount) {
        historyNavigationControls.push(
            <div
                key={historyNavigationControls.length}
                className={styles.emptyNavigation}
            />
        )
    }

    // build component for display
    let historyDisplayPanelComponent: React.ReactElement

    if (selectedHistoryIndex === undefined) {
        historyDisplayPanelComponent = <EmptyHistoryDisplayPanel />
    } else {
        const historyData = history[selectedHistoryIndex]
        const historyDisplayPanelProps =
            getWellControlSettingFragmentFieldsUnion(historyData)

        historyDisplayPanelComponent = (
            <HistoryDisplayPanel
                userRole={userRole}
                controlSetting={historyDisplayPanelProps}
            />
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.buttonRow}>{historyNavigationControls}</div>
            {historyDisplayPanelComponent}
        </div>
    )
}

export default WellControlsHistory

const EmptyHistoryDisplayPanel: FunctionComponent = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Select a history setting</div>
        </div>
    )
}

interface HistoryDisplayPanelPropsInterface {
    controlSetting: ControlSettingFragmentFieldsInterface
    userRole: SubjectRole
}

const HistoryDisplayPanel: FunctionComponent<
    HistoryDisplayPanelPropsInterface
> = ({ controlSetting: { controlSettingFragmentFields }, userRole }) => {
    const identityFrag = getFragmentData(
        IDENTITY_FIELDS_FRAGMENT,
        controlSettingFragmentFields.createdBy.identity
    )
    const createdByName = identityFrag?.fullName

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Information</div>
            <div className={styles.flexContainer}>
                <div className={styles.flexLeft}>
                    <div className={styles.dataContainer}>
                        <span className={styles.dataLabel}>Time:</span>
                        <span className={styles.dataValue}>
                            {convertUnixMillisecondsToLocalTime(
                                controlSettingFragmentFields.createdAt
                                    .unixMilliseconds,
                                {
                                    hour: "numeric",
                                    minute: "2-digit",
                                    hour12: true,
                                    timeZoneName: "short",
                                }
                            )}
                        </span>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataLabel}>Creator:</div>
                        {createdByName && (
                            <ToolTip
                                orientation={"bottom"}
                                toolTipChildren={createdByName}
                            >
                                <div className={styles.dataValueEmail}>
                                    {createdByName}
                                </div>
                            </ToolTip>
                        )}
                    </div>
                    <TimerAndSmartDetails
                        controlSettingFragmentFields={
                            controlSettingFragmentFields
                        }
                        userRole={userRole}
                    />
                </div>
                <div className={styles.description}>
                    {controlSettingFragmentFields.reason}
                </div>
            </div>
        </div>
    )
}

interface TimerAndSmartDetailsI {
    userRole: SubjectRole
    controlSettingFragmentFields: ControlSettingFieldsFragmentCustomUnionType
}

const TimerAndSmartDetails: FunctionComponent<TimerAndSmartDetailsI> = ({
    controlSettingFragmentFields,
    userRole,
}) => {
    switch (controlSettingFragmentFields.__typename) {
        case "WellControlSettingOn":
        case "WellControlSettingOff":
        case "WellControlSettingUnspecified":
            return <></>
        case "WellControlSettingTimer": {
            return (
                <>
                    <div className={styles.dataContainer}>
                        <span style={{ paddingRight: "5px" }}>
                            Timer Cycle:
                        </span>
                        <span className={styles.dataValue}>
                            {` ${controlSettingFragmentFields.period.hours} hr
                    ${controlSettingFragmentFields.period.minutes} min`}
                        </span>
                    </div>
                    <div className={styles.dataContainer}>
                        <span className={styles.dataLabel}>Runtime:</span>
                        <span className={styles.dataValue}>
                            {` ${controlSettingFragmentFields.runRatio * 100}%`}
                        </span>
                    </div>
                </>
            )
        }
        case "WellControlSettingConstantIdle": {
            if (userRole === SubjectRole.PpoAdmin) {
                return (
                    <>
                        <div className={styles.dataContainer}>
                            <span style={{ paddingRight: "5px" }}>
                                Constant Idle:
                            </span>
                            <span className={styles.dataValue}>
                                {` ${controlSettingFragmentFields.constantIdle.hours} hr
                                ${controlSettingFragmentFields.constantIdle.minutes} min`}
                            </span>
                        </div>
                        <div className={styles.dataContainer}>
                            <span className={styles.dataLabel}>
                                Target Empty Ratio:
                            </span>
                            <span className={styles.dataValue}>
                                {`${controlSettingFragmentFields.targetEmptyRatio}`}
                            </span>
                        </div>
                    </>
                )
            } else {
                return <></>
            }
        }
        default:
            return <></>
    }
}
