import {
    Cell as TanStackCell,
    ColumnFiltersState,
    SortingState,
    Table,
    flexRender,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import {
    FunctionComponent,
    useDeferredValue,
    useEffect,
    useMemo,
    useState,
} from "react"
import { AlertRulesTableDataI } from "./tableTypes"
import styles from "./table.module.scss"
import AlertRulesActionHeader from "./actionHeader"
import { DESCRIPTION_COLUMN_ID, alertRulesColumns } from "./column"
import AlertRulesTableHeader from "./header"
import classNames from "classnames"
import TanStackPaginator from "../../../shared/tanStack/paginator"
import { useSearchParams } from "react-router-dom"
import {
    alertRulePageSizeSelectorArray,
    alertRulesTableUrlMapping,
    getAlertRulesStateFromUrlQueryParams,
} from "./tableUtil"
import { tableStateToUrlSearchParams } from "../../../table/tableUtil"
import RadixToolTip from "../../../shared/toolTip/radixToolTip"
import { NOTIFICATIONS_RULES_ABS_ROUTE } from "../../../.."

interface AlertRulesTableI {
    data: AlertRulesTableDataI[]
    key: string
}

const AlertRulesTable: FunctionComponent<AlertRulesTableI> = ({ data }) => {
    // init state from url
    const [searchParams, setSearchParams] = useSearchParams()
    const initUrlState = useMemo(() => {
        return getAlertRulesStateFromUrlQueryParams(
            searchParams,
            alertRulesTableUrlMapping
        )
    }, [data])

    // table state
    const [globalFilter, setGlobalFilter] = useState<string>(
        initUrlState.search
    )
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
        initUrlState.filter
    )

    const [sortingState, setSortingState] = useState<SortingState>(
        initUrlState.sorting
    )
    const [paginationState, setPaginationState] = useState({
        pageIndex: initUrlState.pageIndex,
        pageSize: initUrlState.size,
    })

    // deferred state
    const deferredGlobalFilter = useDeferredValue(globalFilter)
    const deferredColumnFilters = useDeferredValue(columnFilters)
    const isStateDeferred =
        deferredGlobalFilter !== globalFilter ||
        deferredColumnFilters !== columnFilters

    // table instance
    const table = useReactTable({
        columns: alertRulesColumns,
        data: data,
        // autoResetPageIndex is because false : if true, the page index will be reset to 0 whenever the data changes (from a mutation).
        // That creates a bad user experience, in this circumstance if the user updates a rule on page 5 they would be directed back to page 1.
        // When autoResetPageIndex is false, we need to manually reset the page index when updating filters, globals and sorting
        autoResetPageIndex: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: (updater) => {
            table.setPageIndex(0)
            return setGlobalFilter(updater)
        },
        onColumnFiltersChange: (updater) => {
            table.setPageIndex(0)
            return setColumnFilters(updater)
        },
        onPaginationChange: (updater) => {
            return setPaginationState(updater)
        },
        onSortingChange: (updater) => {
            table.setPageIndex(0)
            return setSortingState(updater)
        },
        state: {
            globalFilter: deferredGlobalFilter,
            columnFilters: deferredColumnFilters,
            pagination: paginationState,
            sorting: sortingState,
        },
    })

    // Effect hook to keep app state in sync with URL
    // if the dependencies change based on updated table state - this effect will fire and update the URL
    useEffect(() => {
        // do not set parameters if the current url is a modal and not the rules dashboard
        // This causes issues when navigating directly to a modal
        if (!location.pathname.endsWith(NOTIFICATIONS_RULES_ABS_ROUTE)) {
            return
        }

        const newParams = tableStateToUrlSearchParams(
            paginationState,
            sortingState,
            columnFilters,
            globalFilter
        )
        setSearchParams(newParams, { replace: true })
        // having data as a dependency allows the resynchronization of app state & url state after mutations
    }, [columnFilters, sortingState, globalFilter, paginationState, data])

    return (
        <>
            <div className={styles.tableContainer}>
                <AlertRulesActionHeader
                    globalFilter={globalFilter}
                    table={table}
                />
                <div className={styles.overflowContainer}>
                    <table className={styles.tableClass}>
                        <AlertRulesTableHeader table={table} />
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    className={classNames(
                                        styles.tableRow,
                                        isStateDeferred && styles.deferredRow
                                    )}
                                    key={row.id}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            className={classNames(
                                                styles.tableCell,
                                                styles[`${cell.column.id}_Cell`]
                                            )}
                                            key={cell.id}
                                        >
                                            <Cell cell={cell} />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <TanStackPaginator
                    pageSizeOptions={alertRulePageSizeSelectorArray}
                    tableInstance={table as Table<unknown>}
                />
            </div>
        </>
    )
}

export default AlertRulesTable

interface CellI {
    cell: TanStackCell<AlertRulesTableDataI, unknown>
}

const Cell: FunctionComponent<CellI> = ({ cell }) => {
    if (cell.column.id === DESCRIPTION_COLUMN_ID) {
        return (
            <RadixToolTip
                tooltipContent={cell.getValue() as string}
                triggerChildren={
                    <span
                        className={classNames(styles.cellSpan, styles.tooltip)}
                    >
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                        )}
                    </span>
                }
                side="top"
            />
        )
    } else {
        return (
            <span className={styles.cellSpan}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </span>
        )
    }
}
