import { graphql } from "../../generated"

export const SUBSCRIPTION_FIELDS_FRAGMENT = graphql(`
    fragment SubscriptionFields on AlertSubscription {
        id
        email
        phone
        includeNewWells
        disturb
        silenceUntil {
            ...DateTimeFields
        }
        subscribedWells {
            ...WellIdentificationFields
        }
        subscriber {
            ...SubjectFields
        }
        createdBy {
            ...SubjectFields
        }
        alertRule {
            ...AlertRuleFields
        }
    }
`)

export const SUBSCRIPTION_FRAG_FOR_TRANSFER_SUB_TO_USER = graphql(`
    fragment SubscriptionFragForTransferSubToUser on AlertSubscription {
        subscriptionId: id
        alertRule {
            id
        }
        email
        phone
        disturb
        includeNewWells
        silenceUntil {
            unixMilliseconds
        }
        subscribedWells {
            id
        }
        subscriber {
            subscriberId: id
            identity {
                fullName
            }
        }
    }
`)
