import { createColumnHelper } from "@tanstack/react-table"
import { SubscriptionsAdminTableDataI } from "../../../tableTypes"
import {
    SUBSCRIBED_WELLS_COLUMN_ID,
    SUBSCRIBER_COLUMN_ID,
    EMAIL_COLUMN_ID,
    METRIC_COLUMN_ID,
    RULE_NAME_COLUMN_ID,
    TEXT_COLUMN_ID,
} from "../../../column"
import { ActionMenu } from "../../../../../../shared/tanStack/cell/actionsMenu"
import {
    SubscriberReceiveNotificationsCell,
    SubscriberWellsCell,
    SubscriptionShowSilenceCell,
} from "../../../../sharedComponents/table/cell"
import { useViewerContext } from "../../../../../../../context/ViewerContext"
import { getAdminSubscriptionsMenuByRole } from "../../../../../../../util/rolePermissions/getByRole"

// constants
// Note: column ids are used as keys to style table columns.
// If the column headers are changed in this file, they should be updated in: table.module.scss , header.module.scss
// Note: The column ids are used to keep the urlState in sync with the application state and the column id value will be reflected in the url
// example: /?wellName="PumpMoreOil"
// NOTE: The current implementation of the table will not keep urlState in sync with application state for multisort - to enable mutliSort updates will need to be made, start with the clickHandler that toggles sort ~ /header/index.tsx - <HeaderSortIcon/>

// table columns
const columnHelper = createColumnHelper<SubscriptionsAdminTableDataI>()

export const subscriptionsByUserColumns = [
    columnHelper.accessor("ruleName", {
        id: RULE_NAME_COLUMN_ID,
        header: "Rule Name",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: "alphanumeric",
        cell: ({ row }) => {
            const {
                ruleName,
                subscriptionSilencedUntil: subscriptionSilenced,
            } = row.original
            return (
                <SubscriptionShowSilenceCell
                    cellValue={ruleName}
                    silenced={subscriptionSilenced}
                />
            )
        },
    }),
    columnHelper.accessor("subscribedWells", {
        id: SUBSCRIBED_WELLS_COLUMN_ID,
        header: "Well(s)",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: "alphanumeric",
        cell: ({ row }) => {
            const subscribedWells = row.original.subscribedWells
            return <SubscriberWellsCell wells={subscribedWells} />
        },
    }),
    columnHelper.accessor("email", {
        id: EMAIL_COLUMN_ID,
        header: "Email",
        enableMultiSort: false,
        enableSorting: false,
        cell: ({ row }) => {
            const accept = row.original.email
            return <SubscriberReceiveNotificationsCell accept={accept} />
        },
    }),
    columnHelper.accessor("phone", {
        id: TEXT_COLUMN_ID,
        header: "Text",
        enableMultiSort: false,
        enableSorting: false,
        cell: ({ row }) => {
            const accept = row.original.phone
            return <SubscriberReceiveNotificationsCell accept={accept} />
        },
    }),
    columnHelper.display({
        id: "menu",
        header: "Actions",
        cell: ({ row }) => {
            const { role } = useViewerContext().getViewer()
            const subscriptionId = row.original.id
            const activeSilence = Boolean(
                row.original.subscriptionSilencedUntil
            )

            return (
                <ActionMenu
                    menuOptions={getAdminSubscriptionsMenuByRole(
                        role,
                        subscriptionId,
                        activeSilence
                    )}
                />
            )
        },
    }),
    columnHelper.accessor("metric", {
        id: METRIC_COLUMN_ID,
        header: "",
        cell: () => {
            return <></>
        },
        enableHiding: true,
    }),
    columnHelper.accessor("subscriber", {
        id: SUBSCRIBER_COLUMN_ID,
        header: "Subscriber",
        enableHiding: true,
        // https://tanstack.com/table/v8/docs/guide/column-filtering#filterfns
        filterFn: (row, columnId, filterValue) => {
            return row.getValue(columnId) === filterValue
        },
    }),
]
